<div class="header-content">
  <div class="wrapper-compact-wide">
    <h1>
      Wij vinden uw privacy belangrijk.
    </h1>
   </div>
</div>

<div class="text-wrap">
  <div class="content-terms">
    <p class="p1">Privacy beleid Viteezy B.V.<span class="Apple-converted-space">&nbsp;</span></p>
    <p class="p2">&nbsp;</p>
    <p class="p3"><strong>Over ons privacy beleid<span class="Apple-converted-space">&nbsp;</span></strong></p>
    <p class="p3">Viteezy B.V. respecteert uw privacy. Wij verwerken daarom uitsluitend persoonsgegevens die wij nodig
      hebben voor (het verbeteren van) onze dienstverlening en gaan zorgvuldig om met de informatie die wij over u en uw
      gebruik van onze diensten hebben verzameld. Wij stellen uw gegevens nooit voor commerci&euml;le doelstellingen ter
      beschikking aan derden, zonder dat wij daarvoor uw uitdrukkelijke toestemming hebben gekregen.<span
        class="Apple-converted-space">&nbsp;</span></p>
    <p class="p4">&nbsp;</p>
    <p class="p3">Dit privacy beleid is van toepassing op het gebruik van de website en de daarop ontsloten
      dienstverlening van Viteezy B.V. De ingangsdatum voor de geldigheid van deze voorwaarden is 31/12/2023. Als wij
      een nieuwe versie publiceren op deze website vervalt de geldigheid van alle voorgaande versies.<span
        class="Apple-converted-space">&nbsp;</span></p>
    <p class="p4">&nbsp;</p>
    <p class="p3">Dit privacy beleid beschrijft welke gegevens over u door ons worden verzameld, waar deze gegevens voor
      worden gebruikt en met wie en onder welke voorwaarden deze gegevens eventueel met derden kunnen worden gedeeld.
      Ook leggen wij aan u uit op welke wijze wij uw gegevens opslaan en hoe wij uw gegevens tegen misbruik beschermen
      en welke rechten u heeft met betrekking tot de door u aan ons verstrekte persoonsgegevens.<span
        class="Apple-converted-space">&nbsp;</span></p>
    <p class="p4">&nbsp;</p>
    <p class="p3">Als u vragen heeft over ons privacy beleid kunt u contact opnemen met onze contactpersoon voor privacy
      aken, u vindt de contactgegevens onderaan ons privacy beleid.<span class="Apple-converted-space">&nbsp;</span></p>
    <p class="p4">&nbsp;</p>
    <p class="p3"><strong>Over de gegevensverwerking<span class="Apple-converted-space">&nbsp;</span></strong></p>
    <p class="p3">Hieronder leest u welke gegevens wij verzamelen en/of ontvangen, op welke wijze wij uw gegevens
      verwerken, waar wij deze (laten) opslaan, welke beveiligingstechnieken wij gebruiken en voor wie de gegevens
      inzichtelijk zijn.<span class="Apple-converted-space">&nbsp;</span></p>
    <p class="p4">&nbsp;</p>
    <p class="p3"><strong>De gegevens die wij verzamelen en/of ontvangen.<span
          class="Apple-converted-space">&nbsp;</span></strong></p>
    <p class="p3">Tijdens het gebruikt van de Website en de Diensten verzamelt (en/of ontvangt) Viteezy B.V. de volgende
      soorten informatie. U geeft ons toestemming om dergelijke informatie te verzamelen en/of te ontvangen als u onze
      Diensten gebruikt</p>
    <p class="p4">&nbsp;</p>
    <p class="p3"><strong>Hoe we informatie gebruiken en delen</strong></p>
    <p class="p3">U machtigt ons om de Persoonsgegevens, Bijzondere persoonsgegevens, Facturatiegegevens en
      Productgegevens (gezamenlijk de &lsquo;Gegevens&rsquo;) te gebruiken om de website en de Diensten te leveren en te
      verbeteren; om uw feedback te vragen; en om u te informeren over onze producten en die van onze partners.</p>
    <p class="p4">&nbsp;</p>
    <ul class="ul1">
      <li class="li3"><strong>Persoonsgegevens</strong></li>
    </ul>
    <p class="p3">Om u te helpen bij het samenstellen van uw Gepersonaliseerde Supplementen, vragen wij u ompersoonlijke
      informatie over uzelf te verstrekken, zoals uw naam, e-mail, geslacht, geboortedatum en postcode. Als u een
      abonnement op onze Diensten wilt kopen, moet u ons aanvullend informatie over uzelf verstrekken, zoals uw adres.
      Alle informatie die we in dit gedeelte ontvangen, worden gezamenlijk &lsquo;Persoonsgegevens&rsquo; genoemd. Wij
      verzamelen geen persoonsgegevens van bezoekers wanneer ze de website gebruiken, tenzij ze deze informatie
      vrijwillig verstrekken.<span class="Apple-converted-space">&nbsp;</span></p>
    <p class="p4">&nbsp;</p>
    <p class="p3"><strong>Algemeen doel van de verwerking<span class="Apple-converted-space">&nbsp;</span></strong></p>
    <p class="p3">Wij gebruiken uw Gegevens uitsluitend ten behoeve van onze dienstverlening. Dat wil zeggen dat het
      doel van de verwerking altijd direct verband houdt met de opdracht die u verstrekt. Uw gegevens worden niet met
      derden gedeeld, anders dan om aan boekhoudkundige, technische en overige administratieve verplichtingen te
      voldoen. Deze derden zijn allemaal tot geheimhouding gehouden op grond van de overeenkomst tussen hen en ons of
      een eed of wettelijke verplichting.<span class="Apple-converted-space">&nbsp;</span></p>
    <p class="p4">&nbsp;</p>
    <p class="p3"><strong>Automatisch verzamelde gegevens<span class="Apple-converted-space">&nbsp;</span></strong></p>
    <p class="p3">Gegevens die automatisch worden verzameld door onze website worden verwerkt met het doel onze
      dienstverlening verder te verbeteren. Deze gegevens (bijvoorbeeld uw IP-adres, webbrowser en besturingssysteem)
      zijn geen persoonsgegevens.<span class="Apple-converted-space">&nbsp;</span></p>
    <p class="p4">&nbsp;</p>
    <p class="p4">&nbsp;</p>
    <p class="p4">&nbsp;</p>
    <ul class="ul1">
      <li class="li3"><strong>Bijzondere persoonsgegevens</strong></li>
    </ul>
    <p class="p3">Om u te helpen uw Gepersonaliseerde Supplementen samen te stellen, wordt u gevraagd ons informatie te
      verstrekken over uw gezondheid en welzijn, inclusief maar niet beperkt tot uw gezondheidsdoelen, uw dieet, uw
      levensstijl en uw eetpatroon. Alle informatie die wij in dit gedeelte ontvangen, worden gezamenlijk
      &lsquo;Bijzondere persoonsgegevens&rsquo; genoemd.<span class="Apple-converted-space">&nbsp;</span></p>
    <p class="p4">&nbsp;</p>
    <ul class="ul1">
      <li class="li3"><strong>Facturatie gegevens</strong></li>
    </ul>
    <p class="p3">Om een abonnement op onze Diensten te kopen, moet u naast de hierboven vermelde persoonlijke
      informatie bepaalde informatie verstrekken. Dergelijke informatie kan een creditcardnummer, vervaldatum, postcode
      van de factuur en soortgelijke informatie (gezamenlijk de &lsquo;Facturatie gegevens&rsquo;) omvatten. Dergelijke
      factuurgegevens worden verzameld en verwerkt door onze externe betalingsleverancier in overeenstemming met de
      voorwaarden en bepalingen van hun privacybeleid en gebruiksvoorwaarden.</p>
    <p class="p4">&nbsp;</p>
    <ul class="ul1">
      <li class="li3"><strong>Productgegevens</strong></li>
    </ul>
    <p class="p3">Wanneer klanten een bestelling plaatsen voor hun Gepersonaliseerde Supplementen, zullen we ook
      bepaalde informatie verzamelen en/of ontvangen, inclusief maar niet beperkt tot, de producten in hun
      Gepersonaliseerde Supplementen pakket. Alle informatie die wij in dit gedeelte ontvangen, worden gezamenlijk
      &lsquo;Productgegevens&rsquo; genoemd.</p>
    <p class="p4">&nbsp;</p>
    <p class="p4">&nbsp;</p>
    <p class="p3"><strong>Software<span class="Apple-converted-space">&nbsp; </span>en webhosting</strong></p>
    <p class="p3">Ons platform wordt ontwikkeld en beheerd door onze eigen programmeurs. Onze programmeurs zijn<span
        class="Apple-converted-space">&nbsp; </span>gebonden aan geheimhoudingsverplichtingen. We dragen er zorg voor
      dat onze programmeurs alleen toegang hebben tot uw Gegevens om technische ondersteuning te bieden.</p>
    <p class="p3">Wij nemen cloud hosting diensten af van Google Cloud Hosting. Google Cloud Hosting verwerkt uw
      Gegevens namens ons en gebruikt uw Gegevens niet voor eigen doeleinden. Wel kan deze partij metagegevens
      verzamelen over het gebruik van de diensten. Dit zijn geen persoonsgegevens. Google Cloud Hosting heeft passende
      technische en organisatorische maatregelen genomen om verlies en ongeoorloofd gebruik van uw persoonsgegevens te
      voorkomen. Google Cloud Hosting is op grond van de overeenkomst die wij met haar hebben gesloten tot geheimhouding
      verplicht.<span class="Apple-converted-space">&nbsp;</span></p>
    <p class="p4">&nbsp;</p>
    <p class="p3"><strong>Klaviyo<span class="Apple-converted-space">&nbsp;</span></strong></p>
    <p class="p3">Voor het verzorgen van nieuwsbrieven en e-mailverkeer schakelt Viteezy Klaviyo in als verwerker.
      Klaviyo is gevestigd in de Verenigde Staten, het Verenigd Koninkrijk en Australie en neemt deel aan het
      <strong>Data Privacy Framework.</strong></p>
    <p class="p3">Voor meer informatie over de verwerking van persoonsgegevens door Klaviyo verwijzen wij u naar het
      privacy beleid van Klaviyo: https://www.klaviyo.com/legal/privacy<span class="Apple-converted-space">&nbsp;</span>
    </p>
    <p class="p4">&nbsp;</p>
    <p class="p3"><strong>Mollie<span class="Apple-converted-space">&nbsp;</span></strong></p>
    <p class="p3">Voor het afhandelen van de betalingen in onze webwinkel maken wij gebruik van het platform van Mollie.
      Mollie verwerkt uw naam, adres en woonplaatsgegevens en uw betaalgegevens zoals uw bankrekening- of
      creditcardnummer. Mollie heeft passende technische en organisatorische maatregelen genomen om uw persoonsgegevens
      te beschermen. Mollie behoudt zich het recht voor uw gegevens te gebruiken om de dienstverlening verder te
      verbeteren en in het kader daarvan (geanonimiseerde) gegevens met derden te delen. Alle hierboven genoemde
      waarborgen met betrekking tot de bescherming van uw persoonsgegevens zijn eveneens van toepassing op de onderdelen
      van Mollie&rsquo;s dienstverlening waarvoor zij derden inschakelen. Mollie bewaart uw gegevens niet langer dan op
      grond van de wettelijke termijnen is toegestaan. Voor meer informatie over de verwerking van persoonsgegevens door
      Mollie verwijzen wij u naar het privacy beleid van Mollie: https://www.mollie.com/nl/privacy<span
        class="Apple-converted-space">&nbsp;</span></p>
    <p class="p4">&nbsp;</p>
    <p class="p3"><strong>Trustpilot<span class="Apple-converted-space">&nbsp;</span></strong></p>
    <p class="p3">Wij verzamelen reviews via het platform van Trustpilot. Als u een review achterlaat via Trustpilot dan
      bent u verplicht om uw naam, woonplaats en e-mailadres op te geven. Trustpilot deelt deze gegevens met ons, zodat
      wij de review aan uw bestelling kunnen koppelen. Trustpilot publiceert uw naam en woonplaats eveneens op de eigen
      website. In sommige gevallen kan Trustpilot contact met u opnemen om een toelichting op uw review te geven. In het
      geval dat wij u uitnodigen om een review achter te laten delen wij uw naam en e-mailadres met Trustpilot. Zij
      gebruiken deze gegevens enkel met het doel u uit te nodigen om een review achter te laten. Trustpilot heeft
      passende technische en organisatorische maatregelen genomen om uw persoonsgegevens te beschermen. Trustpilot
      behoudt zich het recht voor om ten behoeve van het leveren van de dienstverlening derden in te schakelen, hiervoor
      hebben wij aan Trustpilot toestemming gegeven. Alle hierboven genoemde waarborgen met betrekking tot de
      bescherming van uw persoonsgegevens zijn eveneens van toepassing op de onderdelen van de dienstverlening waarvoor
      Trustpilot derden inschakelt. Voor meer informatie over de verwerking van persoonsgegevens door Trustpilot
      verwijzen wij u naar het privacy beleid van Trustpilot:
      https://nl.legal.trustpilot.com/for-reviewers/end-user-privacy-terms.<span
        class="Apple-converted-space">&nbsp;</span></p>
    <p class="p4">&nbsp;</p>
    <p class="p3"><strong>PostNL<span class="Apple-converted-space">&nbsp;</span></strong></p>
    <p class="p3">Als u een bestelling bij ons plaatst is het onze taak om uw pakket bij u te laten bezorgen. Wij maken
      gebruik van de diensten van PostNL voor het uitvoeren van de leveringen. Het is daarvoor noodzakelijk dat wij uw
      naam, adres en woonplaatsgegevens met PostNL delen. PostNL gebruikt deze gegevens alleen ten behoeve van het
      uitvoeren van de overeenkomst. In het geval dat PostNL onderaannemers inschakelt, stelt PostNL uw gegevens ook aan
      deze partijen ter beschikking.<span class="Apple-converted-space">&nbsp;</span></p>
    <p class="p4">&nbsp;</p>
    <p class="p3"><strong>Boekhouden<span class="Apple-converted-space">&nbsp;</span></strong></p>
    <p class="p3">Voor het bijhouden van onze administratie en boekhouding maken wij gebruik van de diensten van Exact
      Online. Wij delen uw naam, adres en woonplaatsgegevens en details met betrekking tot uw bestelling. Deze gegevens
      worden gebruikt voor het administreren van verkoopfacturen. Uw persoonsgegevens worden beschermd verzonden en
      opgeslagen. Exact Online is tot geheimhouding verplicht en zal uw gegevens vertrouwelijk behandelen. Exact Online
      gebruikt uw persoonsgegevens niet voor andere doeleinden dan hierboven beschreven.<span
        class="Apple-converted-space">&nbsp;</span></p>
    <p class="p4">&nbsp;</p>
    <p class="p3"><strong>Fulfilment partner</strong></p>
    <p class="p3">Om ervoor te zorgen dat uw bestelling op de juiste wijze wordt samengesteld, verpakt en verzendklaar
      gemaakt, maken wij gebruik van de diensten van een fulfilment dienstverlener. Wij delen uw Productgegevens met
      deze dienstverlener zodat de Gepersonaliseerde Supplementen de juiste samenstelling hebben en naar het juiste
      adres worden verzonden. We hebben met onze fulfilment dienstverlener een verwerkersovereenkomst gesloten. Op basis
      van de overeenkomst mogen de Productgegevens voor geen enkel ander doel worden gebruikt dan hier beschreven.
      Bovendien letten wij erop dat de fulfilment dienstverlener relevante beveiligingsmaatregelen treft en
      geheimhoudingsverplichtingen oplegt aan zijn personeel.<span class="Apple-converted-space">&nbsp;</span></p>
    <p class="p4">&nbsp;</p>
    <p class="p3"><strong>Medewerking aan fiscaal en strafrechtelijk onderzoek<span
          class="Apple-converted-space">&nbsp;</span></strong></p>
    <p class="p3">In voorkomende gevallen kan Viteezy B.V. op grond van een wettelijke verplichting worden gehouden tot
      het delen van uw gegevens in verband met fiscaal of strafrechtelijk onderzoek van overheidswege. In een dergelijk
      geval zijn wij gedwongen uw gegevens te delen, maar wij zullen ons binnen de mogelijkheden die de wet ons biedt
      daartegen verzetten.<span class="Apple-converted-space">&nbsp;</span></p>
    <p class="p4">&nbsp;</p>
    <p class="p3"><strong>Bewaartermijnen<span class="Apple-converted-space">&nbsp;</span></strong></p>
    <p class="p3">Wij bewaren uw gegevens zolang u cli&euml;nt van ons bent. Dit betekent dat wij uw klantprofiel
      bewaren totdat u aangeeft dat u niet langer van onze diensten gebruik wenst te maken. Als u dit bij ons aangeeft
      zullen wij dit tevens opvatten als een vergeetverzoek. Op grond van toepasselijke administratieve verplichtingen
      dienen wij facturen met uw persoonsgegevens te bewaren, deze gegevens zullen wij dus voor zolang de toepasselijke
      termijn loopt bewaren. Medewerkers hebben echter geen toegang meer tot uw cli&euml;ntprofiel en documenten die wij
      naar aanleiding van uw opdracht hebben vervaardigd.<span class="Apple-converted-space">&nbsp;</span></p>
    <p class="p4">&nbsp;</p>
    <ul class="ul1">
      <li class="li3"><strong>Uw Rechten</strong></li>
    </ul>
    <p class="p3">Op grond van de geldende Nederlandse en Europese wetgeving heeft u als betrokkene bepaalde rechten met
      betrekking tot de persoonsgegevens die door of namens ons worden verwerkt. Wij leggen u hieronder uit welke
      rechten dit zijn en hoe u zich op deze rechten kunt beroepen. In beginsel sturen wij om misbruik te voorkomen
      afschriften en kopie&euml;n van uw gegevens enkel naar uw bij ons reeds bekende e-mailadres. In het geval dat u de
      gegevens op een ander e-mailadres of bijvoorbeeld per post wenst te ontvangen, zullen wij u vragen zich te
      legitimeren. Wij houden een administratie bij van afgehandelde verzoeken, in het geval van een vergeetverzoek
      administreren wij geanonimiseerde gegevens. Alle afschriften en kopie&euml;n van gegevens ontvangt u in de machine
      leesbare gegevensindeling die wij binnen onze systemen hanteren. U heeft te allen tijde het recht om een klacht in
      te dienen bij de Autoriteit Persoonsgegevens als u vermoedt dat wij uw persoonsgegevens op een verkeerde manier
      gebruiken.<span class="Apple-converted-space">&nbsp;</span></p>
    <p class="p4">&nbsp;</p>
    <p class="p3"><strong>Inzagerecht<span class="Apple-converted-space">&nbsp;</span></strong></p>
    <p class="p3">U heeft altijd het recht om de gegevens die wij (laten) verwerken en die betrekking hebben op uw
      persoon of daartoe herleidbaar zijn, in te zien. U kunt een verzoek met die strekking doen aan onze contactpersoon
      voor privacyzaken. U ontvangt dan binnen 30 dagen een reactie op uw verzoek. Als uw verzoek wordt ingewilligd
      sturen wij u op het bij ons bekende e-mailadres een kopie van alle gegevens met een overzicht van de verwerkers
      die deze gegevens onder zich hebben, onder vermelding van de categorie waaronder wij deze gegevens hebben
      opgeslagen.<span class="Apple-converted-space">&nbsp;</span></p>
    <p class="p4">&nbsp;</p>
    <p class="p3"><strong>Rectificatierecht<span class="Apple-converted-space">&nbsp;</span></strong></p>
    <p class="p3">U heeft altijd het recht om de gegevens die wij (laten) verwerken en die betrekking hebben op uw
      persoon of daartoe herleidbaar zijn, te laten aanpassen. U kunt een verzoek met die strekking doen aan onze
      contactpersoon voor privacyzaken. U ontvangt dan binnen 30 dagen een reactie op uw verzoek. Als uw verzoek wordt
      ingewilligd sturen wij u op het bij ons bekende e-mailadres een bevestiging dat de gegevens zijn aangepast.<span
        class="Apple-converted-space">&nbsp;</span></p>
    <p class="p4">&nbsp;</p>
    <p class="p3"><strong>Recht op beperking van de verwerking<span class="Apple-converted-space">&nbsp;</span></strong>
    </p>
    <p class="p3">U heeft altijd het recht om de gegevens die wij (laten) verwerken die betrekking hebben op uw persoon
      of daartoe herleidbaar zijn, te beperken. U kunt een verzoek met die strekking doen aan onze contactpersoon voor
      privacyzaken. U ontvangt dan binnen 30 dagen een reactie op uw verzoek. Als uw verzoek wordt ingewilligd sturen
      wij u op het bij ons bekende e-mailadres een bevestiging dat de gegevens tot u de beperking opheft niet langer
      worden verwerkt. Naar alle waarschijnlijkheid kunnen wij in een dergelijk geval de dienstverlening niet langer
      voortzetten, omdat de veilige koppeling van databestanden dan niet langer kan worden gegarandeerd.</p>
    <p class="p4">&nbsp;</p>
    <p class="p3"><strong>Recht op overdraagbaarheid<span class="Apple-converted-space">&nbsp;</span></strong></p>
    <p class="p3">U heeft altijd het recht om de gegevens die wij (laten) verwerken en die betrekking hebben op uw
      persoon of daartoe herleidbaar zijn, door een andere partij te laten uitvoeren. U kunt een verzoek met die
      strekking doen aan onze contactpersoon voor privacyzaken. U ontvangt dan binnen 30 dagen een reactie op uw
      verzoek. Als uw verzoek wordt ingewilligd sturen wij u op het bij ons bekende e-mailadres afschriften of
      kopie&euml;n van alle gegevens over u die wij hebben verwerkt of in opdracht van ons door andere verwerkers of
      derden zijn verwerkt. Naar alle waarschijnlijkheid kunnen wij in een dergelijk geval de dienstverlening niet
      langer voortzetten, omdat de veilige koppeling van databestanden dan niet langer kan worden gegarandeerd.<span
        class="Apple-converted-space">&nbsp;</span></p>
    <p class="p4">&nbsp;</p>
    <p class="p3"><strong>Recht van bezwaar en overige rechten<span class="Apple-converted-space">&nbsp;</span></strong>
    </p>
    <p class="p3">U heeft in voorkomende gevallen het recht bezwaar te maken tegen de verwerking van uw persoonsgegevens
      door of in opdracht van Viteezy B.V. Als u bezwaar maakt zullen wij onmiddellijk de gegevensverwerking staken en
      uw bezwaar zo snel mogelijk in behandeling nemen. U ontvangt binnen 30 dagen een reactie op uw bezwaar.. Is uw
      bezwaar gegrond, dan zullen wij afschriften en/of kopie&euml;n van gegevens die wij (laten) verwerken aan u ter
      beschikking stellen en daarna de verwerking blijvend staken. U heeft bovendien het recht om niet aan
      geautomatiseerde individuele besluitvorming of profiling te worden onderworpen. Wij verwerken uw gegevens niet op
      zodanige wijze dat hiervan sprake is. Bent u van mening dat dit wel zo is, neem dan contact op met onze
      contactpersoon voor privacyzaken.<span class="Apple-converted-space">&nbsp;</span></p>
    <p class="p4">&nbsp;</p>
    <p class="p3"><strong>Cookies - Google Analytics / Facebook / Hotjar</strong></p>
    <p class="p3">Via onze website worden cookies geplaatst van Google, Facebook en Hotjar, als deel van de
      &ldquo;Analytics&rdquo;-dienst. Wij gebruiken deze dienst om bij te houden en rapportages te krijgen over hoe
      bezoekers de website gebruiken. Deze verwerkers zijn mogelijk verplicht op grond van geldende wet- en regelgeving
      inzage te geven in deze gegevens. Wij verzamelen informatie over uw surfgedrag en delen deze gegevens met Google,
      Facebook en/of Hotjar. Google, Facebook en/of Hotjar kan deze informatie in samenhang met andere datasets
      interpreteren en op die manier uw bewegingen op het internet volgen. Google, Facebook en/of Hotjar gebruikt deze
      informatie voor het aanbieden van onder andere gerichte advertenties (Adwords / Facebook Ads) en overige Google,
      Facebook en/of Hotjar gerelateerde diensten en producten.<span class="Apple-converted-space">&nbsp;</span></p>
    <p class="p4">&nbsp;</p>
    <p class="p3"><strong>Wijzigingen in het privacybeleid<span class="Apple-converted-space">&nbsp;</span></strong></p>
    <p class="p3">Wij behouden te allen tijde het recht ons privacybeleid te wijzigen. Op deze pagina vindt u echter
      altijd de meest recente versie. Als het nieuwe privacybeleid gevolgen heeft voor de wijze waarop wij reeds
      verzamelde gegevens met betrekking tot u verwerken, dan brengen wij u daarvan per e-mail op de hoogte.<span
        class="Apple-converted-space">&nbsp;</span></p>
    <p class="p4">&nbsp;</p>
    <p class="p4">&nbsp;</p>
    <p class="p4">&nbsp;</p>
    <p class="p4">&nbsp;</p>
    <p class="p4">&nbsp;</p>
    <p class="p3"><strong>Contactgegevens<span class="Apple-converted-space">&nbsp;</span></strong></p>
    <p class="p3"><strong>Viteezy B.V.<span class="Apple-converted-space">&nbsp;</span></strong></p>
    <p class="p3">Spiegelstraat 38 B1 &ndash; 1405HX te Bussum</p>
    <p class="p3">Contactpersoon voor privacyzaken - Bas van Klaveren<span class="Apple-converted-space">&nbsp;</span>
    </p>
    <p class="p3">E: info&#64;viteezy.nl<span class="Apple-converted-space">&nbsp;</span></p>
    <p class="p4">&nbsp;</p>
  </div>