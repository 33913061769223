<div class="confirmed-page">
  <div class="wrapper wrapper-compact">
    <i class="icon icon-brew" [ngClass]="{'is-loading': loading }"></i>
    <div *ngIf="loading">
      <p>Een moment, we halen de status van je betaling op...</p>
    </div>
    <div *ngIf="!loading">
      <h1 *ngIf="customer.firstName">Hi {{ customer.firstName }}, bedankt voor je bestelling!</h1>
      <h1 *ngIf="!customer.firstName">Bedankt voor je bestelling!</h1>
      <p>In je mail ontvang je je account gegevens en track en trace.</p>
      <a class="button submit-button--3" (click)="toggleReferralPage()">Geef €10,- en ontvang €10,-</a>
    </div>
  </div>
</div>

<div class="referral-page" [ngClass]="{ 'navigation-active': referralPageVisible }">
  <div class="referral-wrapper">
    <i class="close-circle" (click)="toggleReferralPage()">
      <span class="cross">&#10005;</span>
    </i>
    <div class="referral-head-wrapper"></div>
    <div class="referral-text-wrapper">
      <p>Wil je met voordeel aan je gezondheidsdoelen werken? Dat kan! Voor elke vriend, collega of kennis die via jouw doorverwijzing met Viteezy begint, <strong>ontvang jij automatisch &euro;10 korting op je maandelijkse vitamines.</strong></p>
      <hr class="grey-line small">
      <p>En jij bent niet de enige die profiteert, want iedereen die jouw persoonlijke code gebruikt ontvangt maar liefst <strong>&euro;10 korting op hun eerste bestelling vanaf &euro;20.</strong> Win-win dus!</p>
      <p>Deel de volgende kortingscode</p>
      <b>{{ customer?.referralCode }}</b>
      <a href="https://viteezy.nl" target="_blank" id="referral-button">Deel jouw kortingscode via whatsapp</a>
    </div>
  </div>
</div>