<div class="header-content header-image-background header-image-about-us header-central-content about-us-header">
  <div class="wrapper-compact-wide">
    <h1>
      Wij maken het weer makkelijk voor jou
    </h1>
    <p>
      Wij geloven dat iedereen uniek is en eerlijke begeleiding verdient. Met de combinatie van technologie, wetenschap en menselijke begeleiding, maken wij het eenvoudig om de juiste dagelijkse mix van vitamines – mineralen – kruiden en eiwitten thuis te ontvangen.
    </p>
  </div>
</div>

<section class="show-overflow">
  <div class="wrapper">
    <div class="about-usp-wrap">
      <div class="about-usp">
        <h2>Uniek</h2>
        <p>Iedereen is uniek. Wij vinden dat dit ook moet gelden voor je supplementen. Wij leveren gepersonaliseerde, op maat gemaakte supplementen voor iedereen. Onze vragenlijst hebben we samen met ons team van diëtistes ontwikkeld op basis van de huidige wetenschap. De aanbeveling die je aan het einde van de quiz krijgt, sluit dan ook helemaal aan op de behoefte van jouw lichaam én levensstijl.</p>
      </div>
      <div class="about-usp">
        <h2>Transparant</h2>
        <p>Onze aanbeveling bevat precies de supplementen die jij nodig hebt, niets meer en niets minder. Wij laten jou zien waarom wij de producten aanbevelen en uit welke ingrediënten het product bestaat. Wij zullen nooit het grootste assortiment hebben, enkel de producten die voldoen aan onze hoge kwaliteitsstandaarden.</p>
      </div>
      <div class="about-usp">
        <h2>Pure ingrediënten</h2>
        <p>We maken producten die wij zelf ook willen nemen. We maken ook producten voor onze zussen, broers, vrienden en moeders. Dat kan verklaren waarom we zo toegewijd zijn aan de hoogste kwaliteitsstandaard.</p>
      </div>
    </div>
  </div>

  <div class="wrapper content-header">
    <h2>Ons verhaal</h2>
    <p>Viteezy is opgericht, omdat de supplementenmarkt niet transparant en simpel genoeg was. Iedereen is uniek en dat geldt ook voor hun behoeften. Daarom hebben wij de nadruk gelegd op een gepersonaliseerde aanpak die rekening houdt met de levensstijl en doel van elk individu. Onze missie is om het op een transparante manier makkelijk te maken. Wij geloven dat iedereen het verdient om precies te weten wat er in elk product zit. Wij maken het mogelijk om de juiste supplementen te vinden zonder daarvoor te deur uit te hoeven. </p>
  </div>
</section>