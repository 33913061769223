<div class="header-content">
  <div class="wrapper-compact-wide">
    <h1>
      Algemene Voorwaarden Viteezy
    </h1>
    <p>
    </p>
  </div>
</div>

<div class="text-wrap">
  <div class="content-terms">
    <h4><br>Viteezy in het kort</h4><br>
    Viteezy B.V. (“Viteezy” “wij “ons” or “onze”) heet je van harte welkom. We zijn blij dat je een kijkje komt nemen op
    onze website. Op onze Website bieden we je de mogelijkheid om diverse gepersonaliseerde voedingssupplementen te
    bestellen. Daarmee bedoelen we dat je een pakket kunt samenstellen dat is afgestemd op jouw specifieke
    voedingsbehoeften. Daarnaast
    <br><br>
    In deze kleine lettertjes- of “algemene voorwaarden” leggen we vast wat je van ons mag verwachten en wat wij op onze
    beurt van jou verwachten. Onze website is voor iedereen vrij toegankelijk. Voor iedere bezoeker geldt dat we het
    belangrijk vinden dat je deze algemene voorwaarden en ons Privacybeleid (KLIK) hebt gelezen.
    <br><br>
    Als je als Consument gebruik wilt maken van onze Diensten en Producten, dan zijn daarop deze algemene voorwaarden
    van toepassing. We gaan ervan uit dat je akkoord gaat met de inhoud daarvan zodra je onze Diensten en Producten
    afneemt. Om zeker te weten dat je ze hebt gelezen en begrepen, vragen we je om voorafgaand aan het plaatsen van een
    bestelling een vinkje te zetten voor akkoord.
    <br><br>
    Als je niet akkoord gaat met een van deze voorwaarden, maak dan geen gebruik van de Website en of de Diensten. We
    kunnen deze algemene voorwaarden van tijd tot tijd wijzigen. Als je een account bij ons hebt, ontvang je daarvan een
    melding. Bezoekers vragen we om de Website goed in de gaten te houden en regelmatig te controleren of er wijzigingen
    hebben plaatsgevonden.

    <h4><br>Disclaimer: wat we wel èn niet doen.</h4><br>
    <h4><br>1. Wij geven geen medisch advies! Nooit! Ook niet een beetje.</h4><br>
    We willen voorop stellen dat Viteezy geen enkele vorm van medische zorg, medisch advies, diagnose of behandeling
    biedt. Dat kunnen we niet, omdat we geen artsen zijn. Aan de hand van de tools op de Website kun je niet vaststellen
    of je medische zorg nodig hebt. Daarvoor zijn deze tools niet gemaakt en niet geschikt.
    <br><br>
    Heb je gezondheidsklachten, neem dan altijd contact op met je (huis)arts of een andere zorgprofessional. De Website,
    de services en de inhoud hebben uitsluitend informatieve doeleinden en zijn niet bedoeld als vervanging voor
    professioneel medisch advies, diagnose of behandeling. Gebruik de informatie op de website niet voor
    (zelf)diagnostiek of behandeling van een gezondheidsprobleem of ziekte.
    <br><br>
    We raden je aan om contact op te nemen met je arts of een voedingsdeskundige voor je gebruik gaat maken van onze
    Diensten en Producten, zeker als je geneesmiddelen gebruikt, zwanger bent, borstvoeding geeft of bekend bent met een
    ziekte.
    <br><br>
    En wat je ook doet: stel een bezoek aan een arts nooit uit omdat je iets op deze Website- of waar dan ook op het
    Internet- hebt gelezen.

    <h4><br>2. Wat we wel doen</h4><br>
    Via de Diensten helpen we je om je eigen pakket van gepersonaliseerde supplementen ("Gepersonaliseerde
    Supplementen") samen te stellen die we op basis van een lidmaatschap aan jou leveren ("Lidmaatschap") in verpakte
    dozen. De quiz die je op onze Website aantreft, helpt je een idee te krijgen van jouw voedingsbehoeften. Als je de
    quiz hebt ingevuld, krijg je van ons een suggestie over de manier waarop je je dagelijkse voeding kunt aanvullen.
    Uiteraard staat het je vrij deze Producten al dan niet aan je pakket toe te voegen. Onze suggestie is niet bindend
    en je bent niet verplicht om deze Producten te bestellen. Als je een keuze hebt gemaakt, dan zorgen wij ervoor dat
    je je Gepersonaliseerde Supplementen zo snel mogelijk naar je toekomen

    <h4><br>Artikel 1 - Definities</h4><br>
    In deze voorwaarden wordt verstaan onder:
    <ol class="terms-list">
      <li>Lidmaatschap: de overeenkomst tussen Viteezy en Consument, op grond waarvan periodiek Diensten en Producten
        worden geleverd;</li>
      <li>Bedenktijd: de termijn waarbinnen de Consument gebruik kan maken van zijn herroepingsrecht;</li>
      <li>Consument: de natuurlijke persoon die niet handelt voor doeleinden die verband houden met zijn handels-,
        bedrijfs-, ambachts- of beroepsactiviteit;</li>
      <li>Dag: kalenderdag;</li>
      <li>Digitale inhoud: gegevens die in digitale vorm geproduceerd en geleverd worden;</li>
      <li>Diensten: de samenstelling en levering van de Producten, het onderhouden en faciliteren van een digitaal
        platform en verstrekking van informatie;</li>
      <li>Duurovereenkomst: een overeenkomst die strekt tot de regelmatige levering van zaken, Diensten en/of digitale
        inhoud gedurende een bepaalde periode;</li>
      <li>Duurzame gegevensdrager: elk hulpmiddel - waaronder ook begrepen e-mail - dat de Consument of Viteezy in staat
        stelt om informatie die aan hem persoonlijk is gericht, op te slaan op een manier die toekomstige raadpleging of
        gebruik gedurende een periode die is afgestemd op het doel waarvoor de informatie is bestemd, en die
        ongewijzigde reproductie van de opgeslagen informatie mogelijk maakt;</li>
      <li>Herroepingsrecht: de mogelijkheid van de Consument om binnen de bedenktijd af te zien van de overeenkomst op
        afstand;</li>
      <li>Viteezy: de natuurlijke of rechtspersoon die Producten, (toegang tot) digitale inhoud en/of Diensten op
        afstand aan Consumenten aanbiedt;</li>
      <li>Overeenkomst op afstand: een overeenkomst die tussen Viteezy en de Consument wordt gesloten in het kader van
        een georganiseerd systeem voor verkoop op afstand van Producten, digitale inhoud en/of Diensten, waarbij tot en
        met het sluiten van de overeenkomst uitsluitend of mede gebruik gemaakt wordt van één of meer technieken voor
        communicatie op afstand;</li>
      <li>Producten: het pakket van Gepersonaliseerde Supplementen;</li>
      <li>Modelformulier voor herroeping: het in Bijlage I van deze voorwaarden opgenomen Europese modelformulier voor
        herroeping;</li>
      <li>Techniek voor communicatie op afstand: middel dat kan worden gebruikt voor het sluiten van een overeenkomst,
        zonder dat Consument en Viteezy gelijktijdig in dezelfde ruimte hoeven te zijn samengekomen. Denk aan de chat op
        de Website, emailverkeer of telefonisch contact.</li>
      <li>Website: de website van Viteezy: <a href="https://viteezy.nl">www.viteezy.nl</a></li>
    </ol>

    <h4><br>Artikel 2 - Toepasselijkheid en verbindendheid</h4><br>
    <ol class="terms-list">
      <li>Deze algemene voorwaarden zijn van toepassing op elk aanbod van Viteezy en op elke tot stand gekomen
        overeenkomst op afstand tussen Viteezy en Consument.</li>
      <li>Voordat de overeenkomst op afstand wordt gesloten, wordt de tekst van deze algemene voorwaarden aan de
        Consument beschikbaar gesteld.</li>
      <li>Indien de overeenkomst op afstand elektronisch wordt gesloten, dan wordt de tekst van deze algemene
        voorwaarden langs elektronische weg aan de Consument ter beschikking gesteld op zodanige wijze dat deze door de
        Consument op een eenvoudige manier kan worden opgeslagen op een duurzame gegevensdrager, voordat de overeenkomst
        tot stand komt.</li>
      <li>Deze algemene voorwaarden zijn verbindend voor alle Diensten en alle Producten van Viteezy, tenzij
        schriftelijk anders is overeengekomen.</li>
      <li>Indien Viteezy niet steeds strikte naleving van deze voorwaarden verlangt, betekent dit niet dat de bepalingen
        daarvan niet van toepassing zijn, of dat Viteezy het recht zou verliezen om in andere gevallen de stipte
        naleving van de bepalingen van deze voorwaarden te verlangen.</li>
    </ol>

    <h4><br>Artikel 3 - Het aanbod</h4><br>
    <ol class="terms-list">
      <li>Als een aanbod een beperkte geldigheidsduur heeft of onder voorwaarden geschiedt, wordt dit nadrukkelijk in
        het aanbod vermeld.</li>
      <li>Het aanbod bevat een volledige en nauwkeurige omschrijving van de aangeboden Producten en Diensten. De
        beschrijving is voldoende gedetailleerd om een goede beoordeling van het aanbod door de Consument mogelijk te
        maken. Als Viteezy gebruik maakt van afbeeldingen, zijn deze een waarheidsgetrouwe weergave van de aangeboden
        Producten, Diensten en/of digitale inhoud. Alleen als er sprake is van vergissingen of fouten in het aanbod,
        wordt Viteezy daardoor niet gebonden.</li>
      <li>Elk aanbod bevat zodanige informatie, dat voor de Consument duidelijk is wat de rechten en verplichtingen
        zijn, die aan de aanvaarding van het aanbod zijn verbonden.</li>
      <li>De Website bevat veel algemene informatie over en beschrijvingen van de Producten en Diensten. De inhoud van
        de Website komt zorgvuldig tot stand maar Viteezy garandeert niet dat de inhoud te allen tijde volledige,
        actueel of vrij van fouten is.</li>
      <li>Viteezy heeft het recht om zonder vooraankondiging het Productaanbod te wijzigen of de verkoop van bepaalde
        Producten te staken.</li>
    </ol>

    <h4><br>Artikel 4 - De overeenkomst</h4><br>
    <ol class="terms-list">
      <li>De overeenkomst komt, onder voorbehoud van het bepaalde in lid 4, tot stand op het moment van aanvaarding door
        de Consument van het aanbod en het voldoen aan de daarbij gestelde voorwaarden.</li>
      <li>Indien de Consument het aanbod langs elektronische weg heeft aanvaard, bevestigt Viteezy onverwijld langs
        elektronische weg de ontvangst van de aanvaarding van het aanbod. Zolang de ontvangst van deze aanvaarding niet
        door Viteezy is bevestigd, kan de Consument de overeenkomst ontbinden.</li>
      <li>Indien de overeenkomst elektronisch tot stand komt, treft Viteezy passende technische en organisatorische
        maatregelen ter beveiliging van de elektronische overdracht van data en zorgt hij voor een veilige web omgeving.
        Indien de Consument elektronisch kan betalen, zal Viteezy daartoe passende veiligheidsmaatregelen in acht nemen.
      </li>
      <li>De Consument is verplicht actuele en waarheidsgetrouwe informatie aan Viteezy te verstrekken, voor zover dat
        voor de uitvoering van de Diensten of de levering van Producten van belang is. Als de Consument een persoonlijk
        account aanmaakt, houdt hij het daaraan verbonden wachtwoord strikt geheim. De Consument waarborgt dat zijn
        persoonlijke account niet wordt misbruikt en dat hij derden geen toegang geeft tot diens account. Schade die
        voortvloeit uit misbruik van het persoonlijke account, komt voor rekening van de Consument.</li>
      <li>Viteezy kan zich -binnen wettelijke kaders- ervan op de hoogte doen stellen of de Consument aan zijn
        betalingsverplichtingen kan voldoen, alsmede van al die feiten en factoren die van belang zijn voor een
        verantwoord aangaan van de overeenkomst op afstand. Als Viteezy op grond van dit onderzoek goede gronden heeft
        om de overeenkomst niet aan te gaan, is hij gerechtigd gemotiveerd een bestelling of aanvraag te weigeren of aan
        de uitvoering bijzondere voorwaarden te verbinden.</li>
      <li>Viteezy zal uiterlijk bij levering van het Product, de dienst of digitale inhoud aan de Consument de volgende
        informatie, schriftelijk of op zodanige wijze dat deze door de Consument op een toegankelijke manier kan worden
        opgeslagen op een duurzame gegevensdrager, meesturen:
        <ol type="a" class="terms-list">
          <li>het bezoekadres van de vestiging van Viteezy waar de Consument met klachten terecht kan;</li>
          <li>de voorwaarden waaronder en de wijze waarop de Consument van het herroepingsrecht gebruik kan maken, dan
            wel een duidelijke melding inzake het uitgesloten zijn van het herroepingsrecht;</li>
          <li>de informatie over garanties en bestaande service na aankoop;</li>
          <li>de prijs met inbegrip van alle belastingen van het Product en Dienst; voor zover van toepassing de kosten
            van aflevering, de wijze van betaling, aflevering of uitvoering van de overeenkomst op afstand;</li>
          <li>de vereisten voor opzegging van de overeenkomst indien de overeenkomst een duur heeft van meer dan één
            jaar of van onbepaalde duur is;</li>
          <li>indien de Consument een herroepingsrecht heeft, het modelformulier voor herroeping.</li>
        </ol>
      </li>
      <li>In geval van een duurtransactie is de bepaling in het vorige lid slechts van toepassing op de eerste levering.
      </li>
    </ol>

    <h4><br>Artikel 5 - Herroepingsrecht Consumenten bij levering van Producten/Diensten</h4><br>
    <ol class="terms-list">
      <li>Viteezy sluit het herroepingsrecht uit ten aanzien van de Producten. Omdat het gaat om Gepersonaliseerde
        Supplementen (levensmiddelen) die op verzoek van de Consument worden samengesteld, kunnen de Producten niet
        worden teruggenomen.</li>
      <li>Onverminderd het bepaalde in lid 1 van dit artikel, heeft de Consument het recht om vanaf de eerste ontvangst
        van de Producten op basis van het Lidmaatschap, het recht om de overeenkomst met Viteezy binnen 14 dagen en
        zonder opgaaf van redenen te herroepen. De Consument is in dat geval alleen betaling verschuldigd voor de
        Producten die hij voorafgaand aan de herroeping heeft ontvangen.</li>
      <li>Om gebruik te maken van het herroepingsrecht, zal de Consument zich richten naar de door Viteezy bij het
        aanbod en/of uiterlijk bij de levering ter zake verstrekte redelijke en duidelijke instructies. De Consument kan
        gebruik maken van het Model Herroepingsformulier, maar is daartoe niet verplicht. Een mededeling aan Viteezy dat
        de Consument afziet van het Lidmaatschap, volstaat.</li>
    </ol>

    <h4><br>Artikel 5a - Kosten in geval van herroeping door Consumenten</h4><br>
    <ol class="terms-list">
      <li>Indien de Consument gebruik maakt van zijn herroepingsrecht, komen ten hoogste de kosten van reeds geleverde
        Producten voor diens rekening.</li>
    </ol>

    <h4><br>Artikel 6 - Verplichtingen van Viteezy bij herroeping</h4><br>
    <ol class="terms-list">
      <li>Als Viteezy de melding van herroeping door de Consument op elektronische wijze mogelijk maakt, stuurt hij na
        ontvangst van deze melding onverwijld een ontvangstbevestiging.</li>
      <li>Viteezy vergoedt alle betalingen van de Consument, inclusief eventuele leveringskosten door Viteezy in
        rekening gebracht voor nog niet geleverde Producten of Diensten, onverwijld doch binnen 14 dagen volgend op de
        dag waarop de Consument hem de herroeping meldt.</li>
    </ol>

    <h4><br>Artikel 7 - Duurtransacties: duur, opzegging en verlenging</h4><br>
    Opzegging:
    <ol class="terms-list">
      <li>De Consument kan de overeenkomst die voor bepaalde of onbepaalde tijd is aangegaan en die strekt tot het
        maandelijks afleveren van Gepersonaliseerde Supplementen op basis van een Lidmaatschap, te allen tijde opzeggen.
      </li>
      <li>De opzegging vindt plaats in overeenstemming met de volgende opzeggingsregels.
        <ul class="terms-list">
          <li>Opzegging geschiedt op dezelfde wijze als de overeenkomst werd aangegaan. Daarnaast heeft de Consument het
            recht de overeenkomst op te zeggen via de klantenservice, email of via het persoonlijke account.</li>
          <li>Opzegging geschiedt uiterlijk zeven dagen voorafgaand aan het eerstvolgende moment van aflevering.</li>
          <li>De opzegging wordt per ommegaande door Viteezy schriftelijk bevestigd.</li>
        </ul>
      </li>
      Verlenging:
      <li>De overeenkomst die strekt tot het maandelijks afleveren van Gepersonaliseerde Supplementen op basis van een
        Lidmaatschap voor bepaalde tijd aangegaan, wordt in beginsel niet stilzwijgend verlengd of vernieuwd voor een
        bepaalde duur.</li>
      <li>Niettegenstaande het bepaalde in het voorgaande lid, mag Viteezy de overeenkomst stilzwijgend voor onbepaalde
        duur verlengen met inachtneming van de volgende regels:</li>
      <li>De Consument kan de verlengde overeenkomst te allen tijde mag opzeggen met een opzegtermijn van ten hoogste
        één maand. </li>
      <li>Een overeenkomst met beperkte duur met het doel de Consument kennis te laten maken met de Producten en
        Diensten, wordt niet stilzwijgend voortgezet en eindigt automatisch na afloop van de proef- of
        kennismakingsperiode.</li>
      <br><br>
      Duur:
      <li>Als een overeenkomst een duur van meer dan een jaar heeft, mag de Consument na een jaar de overeenkomst te
        allen tijde met een opzegtermijn van ten hoogste één maand opzeggen, tenzij de redelijkheid en billijkheid zich
        tegen opzegging vóór het einde van de overeengekomen duur verzetten.</li>
      <br><br>
      Pauzeren:
      <li>De Consument kan op elk gewenst moment een levering door Viteezy laten uitvallen door het Lidmaatschap te
        pauzeren. Via het persoonlijke account kan de Consument aangeven wanneer de pauze dient in te gaan. De Consument
        is verplicht ten minste zeven dagen vóór de eerstvolgende leverdatum kenbaar te maken dat de leveringen dienen
        te worden gepauzeerd, onder vermelding van de duur van de pauze.</li>
    </ol>

    <h4><br>Artikel 8 - Levering en uitvoering</h4><br>
    <ol class="terms-list">
      <li>Viteezy zal de grootst mogelijke zorgvuldigheid in acht nemen bij het in ontvangst nemen en bij de uitvoering
        van bestellingen van Producten en tot verlening van Diensten.</li>
      <li>Als plaats van levering geldt het adres dat de Consument aan Viteezy kenbaar heeft gemaakt. Viteezy verzendt
        alle bestellingen met PostNL. Consument is verplicht om een eventuele adreswijziging uiterlijk 5 dagen voor de
        eerstvolgende leverdatum kenbaar te maken aan Viteezy.</li>
      <li>Consument is gehouden ervoor te zorgen dat levering van de Producten mogelijk is op het opgegeven adres en
        binnen het door de Consument aangegeven tijdsvenster. Consument gaat er bij voorbaat mee akkoord dat de
        Producten kunnen worden aangeboden bij buren of achtergelaten op een door de Consument aangewezen plek, indien
        de Consument niet in staat is de Producten in ontvangst te nemen.</li>
      <li>Met inachtneming van hetgeen hierover in artikel 4 van deze algemene voorwaarden is vermeld, zal Viteezy
        geaccepteerde bestellingen met bekwame spoed doch gewoonlijk binnen 7 dagen uitvoeren, tenzij een andere
        leveringstermijn is overeengekomen. Indien de bezorging vertraging ondervindt, of indien een bestelling niet dan
        wel slechts gedeeltelijk kan worden uitgevoerd, ontvangt de Consument hiervan uiterlijk 8 dagen nadat hij de
        bestelling geplaatst heeft bericht. De Consument heeft in dat geval het recht om de overeenkomst zonder kosten
        te ontbinden.</li>
      <li>Na ontbinding conform het vorige lid zal Viteezy het bedrag dat de Consument betaald heeft onverwijld
        terugbetalen.</li>
      <li>Het risico van beschadiging en/of vermissing van Producten berust bij Viteezy tot het moment van bezorging aan
        de Consument of een derde conform lid 3, tenzij uitdrukkelijk anders is overeengekomen.</li>
    </ol>

    <h4><br>Artikel 9 - Nakoming overeenkomst en extra garantie</h4><br>
    <ol class="terms-list">
      <li>Viteezy staat er voor in dat de Producten en/of Diensten voldoen aan de overeenkomst, de in het aanbod
        vermelde specificaties, aan de redelijke eisen van deugdelijkheid en/of bruikbaarheid en de op de datum van de
        totstandkoming van de overeenkomst bestaande wettelijke bepalingen en/of overheidsvoorschriften. In het
        bijzonder garandeert Viteezy dat de vermelding van ingrediënten compleet en accuraat is.</li>
      <li>Iedere vorm van garantie komt te vervallen indien een gebrek is ontstaan als gevolg van of voortvloeiend uit
        onoordeelkundig of oneigenlijk gebruik van de Producten of gebruik na de houdbaarheidsdatum.</li>
      <li>Een door Viteezy, diens toeleverancier, fabrikant of importeur verstrekte extra garantie beperkt nimmer de
        wettelijke rechten en vorderingen die de Consument op grond van de overeenkomst tegenover Viteezy kan doen
        gelden als Viteezy is tekortgeschoten in de nakoming van zijn deel van de overeenkomst.</li>
      <li>Onder extra garantie wordt verstaan iedere verbintenis van Viteezy, diens toeleverancier, importeur of
        producent waarin deze aan de Consument bepaalde rechten of vorderingen toekent die verder gaan dan waartoe deze
        wettelijk verplicht is in geval hij is tekortgeschoten in de nakoming van zijn deel van de overeenkomst.</li>
    </ol>

    <h4><br>Artikel 10 - Gebreken, klachttermijnen</h4><br>
    <ol class="terms-list">
      <li>De Consument dient de Producten bij levering of uiterlijk binnen 3 werkdagen daarna te onderzoeken. Hierbij
        dient de Consument in ieder geval na te gaan of het geleverde aan de overeenkomst beantwoordt, te weten:
        <ul>
          <li>of de juiste zaken zijn geleverd;</li>
          <li>of de afgeleverde zaken wat betreft kwantiteit (bijvoorbeeld de hoeveelheid en het aantal) overeenstemmen
            met het overeengekomene;</li>
          <li>of de afgeleverde zaken voldoen aan de overeengekomen kwaliteitseisen of - indien deze ontbreken - aan de
            eisen die gesteld mogen worden voor een normaal gebruik.</li>
        </ul>
      </li>
      <li>Worden zichtbare gebreken of tekorten geconstateerd, dan dient de Consument deze binnen 3 werkdagen na
        aflevering schriftelijk aan Viteezy te melden. Na het verstrijken van deze 3 werkdagen wordt Viteezy geacht
        correct geleverd te hebben.</li>
      <li>Ook indien de Consument tijdig reclameert, blijft zijn verplichting tot betaling en afname van gedane
        bestellingen onverminderd bestaan. Zolang niet aan de volledige betalingsverplichting is voldaan, neemt Viteezy
        klachten niet in behandeling. Viteezy heeft altijd het recht om bij het in behandeling nemen van een klacht, in
        afwachting van de vraag of de klacht terecht is, een aanvullende zekerheid of betaling te verlangen, al dan niet
        op de derdengeldrekening van een door Viteezy aan te wijzen advocaat of notaris, alvorens een klacht in
        behandeling te nemen.</li>
      <li>Op verzoek van Viteezy dient de Consument ingeval van een klacht over een Product, dit Product aan Viteezy te
        retourneren ter beoordeling.</li>
    </ol>

    <h4><br>Artikel 11 - De prijs</h4><br>
    <ol class="terms-list">
      <li>Gedurende de in het aanbod vermelde geldigheidsduur worden de prijzen van de aangeboden Producten en/of
        Diensten niet verhoogd, behoudens prijswijzigingen als gevolg van veranderingen in btw-tarieven.</li>
      <li>In afwijking van het vorige lid kan Viteezy Producten of Diensten waarvan de prijzen gebonden zijn aan
        schommelingen op de financiële markt en waar Viteezy geen invloed op heeft, met variabele prijzen aanbieden.
        Deze gebondenheid aan schommelingen en het feit dat eventueel vermelde prijzen richtprijzen zijn, worden bij het
        aanbod vermeld.</li>
      <li>Prijsverhogingen binnen 3 maanden na de totstandkoming van de overeenkomst zijn alleen toegestaan indien zij
        het gevolg zijn van wettelijke regelingen of bepalingen.</li>
      <li>Prijsverhogingen vanaf 3 maanden na de totstandkoming van de overeenkomst zijn alleen toegestaan indien
        Viteezy dit bedongen heeft en:
        <ol type="a" class="terms-list">
          <li>deze het gevolg zijn van wettelijke regelingen of bepalingen; of</li>
          <li>de Consument de bevoegdheid heeft de overeenkomst op te zeggen met ingang van de dag waarop de
            prijsverhoging ingaat.</li>
        </ol>
      </li>
      <li>De in het aanbod van Producten of Diensten genoemde prijzen zijn inclusief btw.</li>
    </ol>

    <h4><br>Artikel 12 - Betaling</h4><br>
    <ol class="terms-list">
      <li>Voor zover niet anders is bepaald in de overeenkomst of aanvullende voorwaarden, dienen de door de Consument
        verschuldigde bedragen te worden voldaan binnen 14 dagen na het ingaan van de bedenktermijn, of bij het
        ontbreken van een bedenktermijn binnen 14 dagen na het sluiten van de overeenkomst. In geval van een
        overeenkomst tot het verlenen van een dienst, vangt deze termijn aan op de dag nadat de Consument de bevestiging
        van de overeenkomst heeft ontvangen.</li>
      <li>Bij de verkoop van Producten aan Consumenten mag de Consument in algemene voorwaarden nimmer verplicht worden
        tot vooruitbetaling van meer dan 50%. Wanneer vooruitbetaling is bedongen, kan de Consument geen enkel recht
        doen gelden aangaande de uitvoering van de desbetreffende bestelling of dienst(en), alvorens de bedongen
        vooruitbetaling heeft plaatsgevonden.</li>
      <li>De Consument heeft de plicht om onjuistheden in verstrekte of vermelde betaalgegevens onverwijld aan Viteezy
        te melden.</li>
      <li>Indien de Consument niet tijdig aan zijn betalingsverplichting(en) voldoet, is deze, nadat hij door Viteezy is
        gewezen op de te late betaling en Viteezy de Consument een termijn van 14 dagen heeft gegund om alsnog aan zijn
        betalingsverplichtingen te voldoen, na het uitblijven van betaling binnen deze 14-dagen-termijn, is de Consument
        in verzuim. Over het nog verschuldigde bedrag is de wettelijke rente verschuldigd en Viteezy is gerechtigd de
        door hem gemaakte buitengerechtelijke incassokosten in rekening te brengen. Deze incassokosten bedragen
        maximaal: 15% over openstaande bedragen tot € 2.500,=; 10% over de daaropvolgende € 2.500,= en 5% over de
        volgende € 5.000,= met een minimum van € 40,=. </li>
      <li>Indien de Consument in verzuim is met de (tijdige) nakoming van enige (betalings)verplichting, dan komen,
        naast de hoofdsom en rente, alle kosten ter verkrijging van voldoening buiten rechte voor rekening van de
        Consument.</li>
      <li>Wanneer toepassing van de schuldsaneringsregeling ten aanzien van Consument wordt uitgesproken, of wanneer er
        beslag wordt gelegd op een wezenlijk deel van zijn/haar vermogen alsmede in geval van overmacht, worden alle
        verplichtingen van de Consument uit hoofde van enige overeenkomst onmiddellijk opeisbaar. Viteezy is dan bevoegd
        om de uitvoering van enige met de Consument gesloten overeenkomst op te schorten dan wel over te gaan tot
        ontbinding van die overeenkomst.</li>
      <li>Onverminderd het voorgaande, is Viteezy gerechtigd het Lidmaatschap te beëindigen op het moment dat de
        Consument in verzuim is. Alle verplichtingen die de Consument jegens Viteezy heeft uit hoofde van de
        Overeenkomst worden onmiddellijk opeisbaar.</li>
    </ol>

    <h4><br>Artikel 13 - Eigendomsvoorbehoud</h4><br>
    <ol class="terms-list">
      <li>Het door in het kader van de overeenkomst geleverde blijft eigendom van Viteezy totdat de Consument alle
        verplichtingen uit de met Viteezy gesloten overeenkomst(en) deugdelijk is nagekomen.</li>
      <li>Het door Viteezy geleverde, dat ingevolge lid 1. onder het eigendomsvoorbehoud valt, mag niet worden
        doorverkocht.</li>
      <li>Voor het geval Viteezy zijn in dit artikel aangeduide eigendomsrechten wil uitoefenen, geeft de Consument bij
        voorbaat onvoorwaardelijke en niet herroepelijke toestemming aan Viteezy en door Viteezy aan te wijzen derden om
        al die plaatsen te betreden waar de eigendommen van Viteezy zich bevinden en deze terug te nemen.</li>
    </ol>

    <h4><br>Artikel 14 - Intellectueel eigendom</h4><br>
    <ol class="terms-list">
      <li>Viteezy behoudt zich de rechten en bevoegdheden voor die hem toekomen op grond van de Auteurswet, de Wet op de
        naburige rechten en overige wet- en regelgeving.</li>
      <li>De intellectuele eigendomsrechten op de Producten en Diensten blijven bij Viteezy en eventuele derde
        rechthebbende(n). De Consument verkrijgt enkel het gebruiksrecht.</li>
      <li>Het uitvoeren van de overeenkomst door Viteezy houdt niet in de overdracht van intellectuele eigendomsrechten
        die bij Viteezy of bij derden rusten. Alle intellectuele eigendomsrechten die ontstaan tijdens, of voortvloeien
        uit, de uitvoering van de overeenkomst behoren toe aan Viteezy.</li>
      <li>Niettegenstaande het bepaalde in artikel 13, zijn de door Viteezy geleverde Producten niet bestemd voor
        doorverkoop. Indien de Consument in strijd handelt met deze bepaling, heeft Viteezy het recht de overeenkomst
        met onmiddellijke ingang te ontbinden en schadevergoeding te vorderen voor alle uit de onbevoegde doorverkoop
        ontstane schade.</li>
    </ol>

    <h4><br>Artikel 15 - Overmacht</h4><br>
    <ol class="terms-list">
      <li>Viteezy is niet gehouden tot het nakomen van enige verplichting jegens de Consument indien hij daartoe
        gehinderd wordt als gevolg van een omstandigheid die niet is te wijten aan schuld en noch krachtens de wet, een
        rechtshandeling of in het verkeer geldende opvattingen voor zijn rekening komt.</li>
      <li>Onder overmacht wordt in deze algemene voorwaarden, naast hetgeen daaromtrent in de wet en jurisprudentie
        wordt begrepen, het volgende verstaan: alle van buitenkomende oorzaken, voorzien of niet-voorzien, waarop
        Viteezy geen invloed kan uitoefenen, doch waardoor Viteezy niet in staat is zijn verplichtingen na te komen.
        Werkstakingen in het bedrijf van Viteezy of van derden worden daaronder begrepen. Viteezy heeft ook het recht
        zich op overmacht te beroepen indien de omstandigheid die (verdere) nakoming van de overeenkomst verhindert,
        intreedt nadat Viteezy zijn verbintenis had moeten nakomen.</li>
      <li>Viteezy kan gedurende de periode dat de overmacht voortduurt de verplichtingen uit de overeenkomst opschorten.
        Indien deze periode langer duurt dan twee maanden, is ieder der partijen gerechtigd de overeenkomst te ontbinden
        zonder verplichting tot vergoeding van schade aan de andere partij.</li>
      <li>Voor zoverre Viteezy ten tijde van het intreden van overmacht zijn verplichtingen uit de overeenkomst
        inmiddels (gedeeltelijk) is nagekomen of deze zal kunnen nakomen, en aan het nagekomen respectievelijk na te
        komen gedeelte zelfstandige waarde toekomt, is Viteezy gerechtigd om het reeds nagekomen respectievelijk na te
        komen gedeelte separaat te factureren. De Consument is gehouden deze factuur te voldoen als ware er sprake van
        een afzonderlijke overeenkomst.</li>
    </ol>

    <h4><br>Artikel 16 - Aansprakelijkheid</h4><br>
    <ol class="terms-list">
      <li>Indien Viteezy aansprakelijk mocht zijn, dan is deze aansprakelijkheid beperkt tot hetgeen in deze bepaling is
        geregeld.</li>
      <li>Viteezy is niet aansprakelijk voor schade, van welke aard ook, ontstaan doordat Viteezy is uitgegaan van door
        of namens de Consument verstrekte onjuiste en /of onvolledige gegevens.</li>
      <li>Indien Viteezy aansprakelijk mocht zijn voor enigerlei schade, dan is de aansprakelijkheid van Viteezy beperkt
        tot maximaal de factuurwaarde van de order, althans tot dat gedeelte van de order waarop de aansprakelijkheid
        betrekking heeft</li>
      <li>De aansprakelijkheid van Viteezy is in ieder geval steeds beperkt tot het bedrag der uitkering van zijn
        verzekeraar in voorkomend geval en met een maximum van het bedrag dat wordt gedekt en uitbetaald onder de
        aansprakelijkheidsverzekering van Viteezy.</li>
      <li>Viteezy is alleen aansprakelijk voor directe schade, verliezen of kosten en dergelijke als gevolg van een
        ernstige tekortkoming die aan Viteezy is toe te rekenen, voor zover deze tekortkoming aantoonbaar voortvloeit
        uit opzettelijk wangedrag of opzettelijke roekeloosheid. Viteezy is nimmer aansprakelijk voor bedrijfsschade,
        gevolgschade en / of indirecte schade, verliezen of kosten en dergelijke.</li>
      <li>Onder directe schade wordt uitsluitend verstaan de redelijke kosten ter vaststelling van de oorzaak en de
        omvang van de schade, voor zover de vaststelling betrekking heeft op schade in de zin van deze voorwaarden, de
        eventuele redelijke kosten gemaakt om de gebrekkige prestatie van Viteezy aan de overeenkomst te laten
        beantwoorden, voor zoveel deze aan Viteezy toegerekend kunnen worden en redelijke kosten, gemaakt ter voorkoming
        of beperking van schade, voor zover de Consument aantoont dat deze kosten hebben geleid tot beperking van
        directe schade als bedoeld in deze algemene voorwaarden.</li>
      <li>Viteezy is nimmer aansprakelijk voor indirecte schade, daaronder begrepen gevolgschade, gederfde winst,
        gemiste besparingen en schade door bedrijfsstagnatie.</li>
      <li>De in dit artikel opgenomen beperkingen van de aansprakelijkheid gelden niet indien de schade te wijten is aan
        opzet of grove schuld van Viteezy of zijn leidinggevende ondergeschikten.</li>
      <li>Alle vorderingsrechten van de Consument op Viteezy vervallen één jaar na de datum van het incident waarop de
        claim is gebaseerd.</li>
    </ol>

    <h4><br>Artikel 17 - Vrijwaring</h4><br>
    <ol class="terms-list">
      <li>De Consument vrijwaart Viteezy voor eventuele aanspraken van derden, die in verband met de uitvoering van de
        overeenkomst schade lijden en waarvan de oorzaak aan andere dan aan Viteezy toerekenbaar is.</li>
      <li>Indien Viteezy uit dien hoofde door derden mocht worden aangesproken, dan is de Consument gehouden Viteezy
        zowel buiten als in rechte bij te staan en onverwijld al hetgeen te doen dat van hem in dat geval verwacht mag
        worden. Mocht de Consument in gebreke blijven in het nemen van adequate maatregelen, dan is Viteezy, zonder
        ingebrekestelling, gerechtigd zelf daartoe over te gaan. Alle kosten en schade aan de zijde van Viteezy en
        derden daardoor ontstaan, komen integraal voor rekening en risico van de Consument.</li>
    </ol>

    <h4><br>Artikel 18 - Klachtenregeling</h4><br>
    <ol class="terms-list">
      <li>Viteezy beschikt over een voldoende bekend gemaakte klachtenprocedure en behandelt de klacht overeenkomstig
        deze klachtenprocedure.</li>
      <li>Klachten over de uitvoering van de overeenkomst moeten binnen bekwame tijd na het ontstaan van de klacht,
        volledig en duidelijk omschreven worden ingediend bij Viteezy.</li>
      <li>Bij Viteezy ingediende klachten worden binnen een termijn van 14 dagen gerekend vanaf de datum van ontvangst
        beantwoord. Als een klacht een voorzienbaar langere verwerkingstijd vraagt, wordt door Viteezy binnen de termijn
        van 14 dagen geantwoord met een bericht van ontvangst en een indicatie wanneer de Consument een meer uitvoerig
        antwoord kan verwachten.</li>
      <li>Indien de klacht niet binnen een redelijke termijn dan wel binnen 3 maanden na het indienen van de klacht in
        onderling overleg kan worden opgelost ontstaat een geschil dat vatbaar is voor de geschillenregeling.</li>
    </ol>

    <h4><br>Artikel 19 - Toepasselijk recht en geschillenregeling</h4><br>
    <ol class="terms-list">
      <li>Op alle rechtsbetrekkingen waarbij Viteezy partij is, is uitsluitend het Nederlands recht van toepassing, ook
        indien aan een verbintenis geheel of gedeeltelijk in het buitenland uitvoering wordt gegeven of indien de bij de
        rechtsbetrekking betrokken partij aldaar woonplaats heeft. De toepasselijkheid van het Weens Koopverdrag wordt
        uitgesloten.</li>
      <li>De rechter in de vestigingsplaats van Viteezy is bij uitsluiting bevoegd van geschillen kennis te nemen,
        tenzij de wet dwingend anders voorschrijft. Niettemin heeft Viteezy het recht het geschil voor te leggen aan de
        volgens de wet bevoegde rechter.</li>
      <li>Partijen zullen eerst een beroep op de rechter doen nadat zij zich tot het uiterste hebben ingespannen een
        geschil in onderling overleg te beslechten.</li>
    </ol>

    <h4><br>Artikel 20 - Aanvullende of afwijkende bepalingen</h4><br>
    <ol class="terms-list">
      <li>Aanvullende dan wel van deze algemene voorwaarden afwijkende bepalingen mogen niet ten nadele van de Consument
        zijn en dienen schriftelijk te worden vastgelegd dan wel op zodanige wijze dat deze door de Consument op een
        toegankelijke manier kunnen worden opgeslagen op een duurzame gegevensdrager.</li>
    </ol>

    <h4><br>Artikel 21 - Vervaltermijn</h4><br>
    <ol class="terms-list">
      <li>Voor zover in deze algemene voorwaarden niet anders is bepaald, vervallen vorderingsrechten en andere
        bevoegdheden van Consument uit welke hoofde ook jegens Viteezy in verband met de verrichte of te verrichten
        Diensten/ geleverde of te leveren Producten door Viteezy in ieder geval na één jaar (twaalf maanden) na het
        moment waarop Consument bekend werd of redelijkerwijs bekend kon zijn met het bestaan van deze rechten en
        bevoegdheden.</li>
    </ol>

    <h4><br>Artikel 22 – Gegevens van Viteezy</h4><br>
    Naam: Viteezy B.V.
    Adres: Spiegelstraat 38, 1405 HX Bussum
    Email: info&#64;viteezy.nl
    Btw-nummer: NL860326007B01
    KvK-nummer: 75567067
  </div>
</div>


