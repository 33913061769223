import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as angular from 'angular';
import { downgradeInjectable } from '@angular/upgrade/static';
import { environment } from 'src/environments/environment';

@Injectable()
export class KlaviyoService {

  constructor(private http: HttpClient) { }

  public notifyCheckout(blendExternalReference: string): Observable<any> {
    return this.http.post<any>(`${environment.serverUrl}/klaviyo/notify-checkout/blend/${blendExternalReference}`, {});
  }
}

angular.module('core.klaviyo', [])
  .factory('klaviyo', downgradeInjectable(KlaviyoService));