<div class="product-page">
  <shopping-cart addedProduct="{{ingredientAdded}}"></shopping-cart>

  <div class="product-container">
    <div class="product-child">
      <img class="product-head-picture" [src]="content?.productImage.data?.attributes.url">
    </div>

    <img class="leaf-top-right" src="/assets/image/icons/leaf.svg">
    <div class="product-child">
      <div class="product-head-info">
        <h2>{{ content?.title }}</h2>
        <p>{{ content?.description }}</p>
        <hr class="grey-hr">
        <ul>
          <li *ngFor="let check of content?.checks">{{ check.text }}</li>
        </ul>
        <hr class="grey-hr">
        <button *ngIf="checkout" class="button submit-button" (click)="goToCheckout()">{{ content?.goToCartText }}</button>
        <button class="button submit-button" (click)="addProduct()">{{ content?.addToCartText }}</button>
      </div>
    </div>
  </div>
  
  <div class="characteristics-container">
    <div class="characteristics-child" *ngFor="let diet of content?.diets">
      <img [src]="diet?.image.data?.attributes.url">
      <span>{{ diet?.text }}</span>
    </div>
  </div> 

  <h3 class="finding-title">{{ content?.findingTitle }}</h3>
  <div class="finding-container">
    <div class="finding-info">
      <h4>{{ content?.findingSubTitle1 }}</h4>
      <p>{{ content?.findingText1 }}</p>
    </div>
    <img class="finding-picture" [src]="content?.findingImage1.data?.attributes.url">
  </div>
  <div class="finding-container">
    <img class="finding-picture" [src]="content?.findingImage2.data?.attributes.url">
    <div class="finding-info">
      <h4>{{ content?.findingSubTitle2 }}</h4>
      <p>{{ content?.findingText2 }}</p>
    </div>
  </div>

  <img class="orange-dots" src="/assets/image/icons/orange-dots.svg">
  <div class="fact-wrapper">
    <span class="fact-tooltip">{{ content?.factTitle }}</span>
    <p class="fact-title">{{ content?.factText }}</p>
  </div>

  <div class="our-product" [ngStyle]="{'background-image': content ? 'url(' + content?.ourProductImage.data?.attributes.url + ')' : 'none'}">
    <div class="visual-cta visual-cta--compact">
      <div class="visual-cta--section">
        <h3>{{ content?.ourProductTitle }}</h3>
      </div>
      <div class="visual-cta--section">
        <p>{{ content?.ourProductText }}</p>
      </div>
    </div>
  </div>

  <div class="ingredient-table-wrapper">
    <div class="table-container">
      <table class="ingredient-table">
        <tr *ngFor="let header of content?.ingredientComponentHeaders">
          <th class="first-row">{{ header.text }}</th>
          <th>{{ header.amount }}</th>
          <th>{{ header.percentage }}</th>
        </tr>
        <tr *ngFor="let component of ingredient?.components">
          <td>{{ component.name }}</td>
          <td>{{ component.amount }}</td>
          <td>{{ component.percentage }}</td>
        </tr>
      </table>
    </div>
  
    <p *ngFor="let notice of content?.ingredientComponentNotices">{{ notice.text }}</p>
  </div>

  <div class="interested-wrapper">
    <h3>{{ content?.additionalProductTitle }}</h3>
    <div class="interested-container">
      <a href="/{{ ingredient.url }}" *ngFor="let ingredient of content?.additionalProducts">
        <div class="interested-item">
          <img [src]="ingredient?.image.data?.attributes.url">
          <h5>{{ ingredient.title }}</h5>
        </div>
      </a>
    </div>
  </div>

  <div class="review-wrapper">
    <h3>{{ content?.reviewTitle }} </h3>
    <reviews></reviews>
  </div>

  <div class="wrapper">
    <div class="visual-cta">
      <div class="visual-cta--section">
        <span>{{ content?.ctaStartQuiz.data?.attributes.title }}</span>
      </div>
      <div class="visual-cta--section">
        <p>{{ content?.ctaStartQuiz.data?.attributes.text }}</p>
      </div>
      <div class="visual-cta--section">
        <a href="/quiz-v2" class="button submit-button--3 submit-button--small">{{ content?.ctaStartQuiz.data?.attributes.buttonText }}<i class="icon icon-interface-arrow-right"></i></a>
      </div>
      <svg width="101px" height="107px" viewBox="0 0 101 107" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <g id="Home" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g id="Home-Mobile" transform="translate(-283.000000, -5230.000000)" fill="#FBB158"> <g id="Group-9" transform="translate(283.000000, 5230.000000)"> <g id="Group-3" transform="translate(43.285714, 0.000000)"> <path d="M13.6265783,4.3012103 C-0.831789447,15.9515891 -5.67454888,27.2603785 8.49980745,40.2378685 C15.8345854,46.9519171 22.5955145,56.177738 33.7724576,51.0277327 C41.0548026,47.6743424 54.3144964,35.7288884 56.6419339,28.2124681 C60.114156,17.0054287 47.8244705,12.3699879 40.3309375,7.95112935 C34.4133039,4.46401035 19.5223649,-5.32725 13.6265783,4.3012103" id="Fill-1"></path> </g> <g id="Group-6" transform="translate(0.000000, 38.894410)"> <path d="M23.0600645,19.8269153 C27.6989234,12.362243 28.0828488,6.55829898 19.6797884,2.98873717 C15.332482,1.1415714 10.8437294,-1.91600425 6.64797254,2.13255862 C3.91430776,4.76993274 -0.140002895,12.1786737 0.00288665764,15.8973856 C0.217942652,21.440317 6.45745314,21.5851647 10.5160938,22.3825437 C13.7173971,23.0106948 21.9400414,25.0457322 23.0600645,19.8269153" id="Fill-4"></path> </g> <path d="M83.6177971,102.788803 C88.7037898,94.5012645 89.1245787,88.056218 79.9139788,84.0933159 C75.1479608,82.0434882 70.2285303,78.6481854 65.6290747,83.1433957 C62.6338764,86.0725613 58.1892944,94.2968699 58.3456292,98.4244641 C58.5808618,104.579829 65.4216024,104.738639 69.8676455,105.625329 C73.3786023,106.32233 82.3890353,108.582434 83.6177971,102.788803" id="Fill-7"></path> </g> </g> </g> </svg>
    </div>
  </div>

  <div class="disclaimer-wrapper">
    <p>{{ content?.claim }}</p>
  </div>
</div>